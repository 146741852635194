import React, { useState } from 'react';
import cx from './onBoard.module.scss';
import { Button, ProfilePicture, Typography } from 'src/components/common';
import { InputField } from 'src/components/common/formFields';
import { componentSizeVariantsEnum } from 'src/constants';
import { Col, Row } from 'react-bootstrap';
import OptionsField from '../../applyToProgram/previewField/OptionsField';
import { MdAdd, MdRemove } from 'react-icons/md';

interface TOnboardProps {
  handleBack: () => void;
}
export default function OnBoard({ handleBack }: TOnboardProps) {
  const [teamType, setTeamType] = useState<string>('');
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setTeamType(e.target.id)
  }
  return (
    <div className={`${cx.onboard}`}>
      <div className={`${cx.titleHeader}`}>
        <h5 className={`${cx.title}`}>On-Board</h5>
        <Button
          size="small"
          variant="primary"
          className="program-actions__actions__next"
          style={{ width: 'auto' }}
          type="button"
          onClick={handleBack}
        >
          Back
        </Button>
      </div>
      <div className='insideCard'>
        <Row>
          <div className={cx.container}>
            <Col md={3}>
              <div className={`${cx.inputFields}`}>
                <OptionsField options={['team', 'startup']} onChange={handleChange} value={teamType} type='singleChoice' required={false} label="" id='team-type' />
              </div>
            </Col>
            <Col md={9}></Col>
            <Col md={6}>
              <div className={`${cx.inputFields}`}>
                <InputField
                  required
                  label="Name"
                  id="name"
                  variant={componentSizeVariantsEnum.SMALL}
                  placeholder="Name"
                  type="text"
                />
              </div>
            </Col>
            <Col md={6}></Col>
            <Col md={6}>
              <div className={`${cx.inputFields}`}>
                <InputField
                  label="Search and select your company ‘s team members"
                  id="teamName"
                  variant={componentSizeVariantsEnum.SMALL}
                  placeholder="Search and add your company ‘s team members"
                  type="search"
                />
              </div>
            </Col>
            <Col md={6}></Col>
          </div>
          <Col md={3}>
            <div className={`${cx.teamCard}`}>
              <div className={cx.profile}>
                <ProfilePicture
                  url={''}
                  fallback={'A'}
                />
                <div className={cx.teamDetails}>
                  <Typography
                    as="p"
                    variant="body-2"
                    fontWeight="semiBold"
                    className="key-member-card__header__user-info__user__name"
                  >
                    {'Adil'}
                  </Typography>
                  <Typography
                    as="p"
                    variant="caption"
                    fontWeight="regular"
                    className="key-member-card__header__user-info__user__role"
                  >
                    {'Enterpreneur'}
                  </Typography>
                </div>
              </div>
              <div className={cx.addRemoveButton}>
                <span><MdAdd /></span>
                <span><MdRemove /></span>
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className={`${cx.teamCard}`}>
              <div className={cx.profile}>
                <ProfilePicture
                  url={''}
                  fallback={'A'}
                />
                <div className={cx.teamDetails}>
                  <Typography
                    as="p"
                    variant="body-2"
                    fontWeight="semiBold"
                    className="key-member-card__header__user-info__user__name"
                  >
                    {'Adil'}
                  </Typography>
                  <Typography
                    as="p"
                    variant="caption"
                    fontWeight="regular"
                    className="key-member-card__header__user-info__user__role"
                  >
                    {'Enterpreneur'}
                  </Typography>
                </div>
              </div>
              <div className={cx.addRemoveButton}>
                <span><MdAdd /></span>
                <span><MdRemove /></span>
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className={`${cx.teamCard}`}>
              <div className={cx.profile}>
                <ProfilePicture
                  url={''}
                  fallback={'A'}
                />
                <div className={cx.teamDetails}>
                  <Typography
                    as="p"
                    variant="body-2"
                    fontWeight="semiBold"
                    className="key-member-card__header__user-info__user__name"
                  >
                    {'Adil'}
                  </Typography>
                  <Typography
                    as="p"
                    variant="caption"
                    fontWeight="regular"
                    className="key-member-card__header__user-info__user__role"
                  >
                    {'Enterpreneur'}
                  </Typography>
                </div>
              </div>
              <div className={cx.addRemoveButton}>
                <span><MdAdd /></span>
                <span><MdRemove /></span>
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className={`${cx.teamCard}`}>
              <div className={cx.profile}>
                <ProfilePicture
                  url={''}
                  fallback={'A'}
                />
                <div className={cx.teamDetails}>
                  <Typography
                    as="p"
                    variant="body-2"
                    fontWeight="semiBold"
                    className="key-member-card__header__user-info__user__name"
                  >
                    {'Adil'}
                  </Typography>
                  <Typography
                    as="p"
                    variant="caption"
                    fontWeight="regular"
                    className="key-member-card__header__user-info__user__role"
                  >
                    {'Enterpreneur'}
                  </Typography>
                </div>
              </div>
              <div className={cx.addRemoveButton}>
                <span><MdAdd /></span>
                <span><MdRemove /></span>
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className={`${cx.teamCard}`}>
              <div className={cx.profile}>
                <ProfilePicture
                  url={''}
                  fallback={'A'}
                />
                <div className={cx.teamDetails}>
                  <Typography
                    as="p"
                    variant="body-2"
                    fontWeight="semiBold"
                    className="key-member-card__header__user-info__user__name"
                  >
                    {'Adil'}
                  </Typography>
                  <Typography
                    as="p"
                    variant="caption"
                    fontWeight="regular"
                    className="key-member-card__header__user-info__user__role"
                  >
                    {'Enterpreneur'}
                  </Typography>
                </div>
              </div>
              <div className={cx.addRemoveButton}>
                <span><MdAdd /></span>
                <span><MdRemove /></span>
              </div>
            </div>
          </Col>
        </Row>
      </div >
    </div>
  )
}
