import { useRef, ChangeEvent, useState, useEffect } from 'react';
import { ActionIcon, Card, Typography } from 'src/components/common';
import { EditIcon, TrashIcon, UploadIcon } from 'src/components/common/common.icons';
import { componentSizeVariantsEnum } from 'src/constants';
import InputField from 'src/components/common/formFields/inputField/InputField';
import { useUploadMedia, useGetFormikField, useToggleOpenModal } from 'src/hooks';
import { useAppSelector } from 'src/store/hooks';
import { getMimeType, getUuid } from 'src/utils';
import ErrorMessage from 'src/components/common/formFields/errorMessage/ErrorMessage';

import './dynamicField.scss';
import { TDynamicFieldProps } from './dynamicField.types';

import AddEditProgramDetailsFields from '../addEditProgramDetailsFields/AddEditProgramDetailsFields';
import { TFieldType, TCustomField, TProgramFileType } from '../programDetails.types';
import FileCard from '../../../fileCard/FileCard';

const editProgramDetailsDateUploadFileFieldsId = 'editProgramDetailsDateUploadFileFields';

const UploadField = ({ id, type, setCustomFields, deleteField, errors, ...rest }: TDynamicFieldProps) => {
  // Hooks
  const { getFormikField } = useGetFormikField();
  const toggleOpenModal = useToggleOpenModal();
  const modalId: string = useAppSelector((store) => store.common.modalId);
  const { handleUploadMedia } = useUploadMedia();

  // State
  const [editFieldModalData, setEditFieldModalData] = useState<{
    id: string;
    fieldType: TFieldType;
  }>({ id: '', fieldType: 'label' });
  const dragDropRef = useRef<HTMLDivElement>(null);
  const uploadImageRef = useRef<HTMLInputElement>(null);

  // Constants
  const isEditProgramDetailsFieldsOpen = modalId === editProgramDetailsDateUploadFileFieldsId;

  const openImageFileManager = async () => {
    if (uploadImageRef.current) {
      uploadImageRef.current.click();
    }
  };

  const [field, , helpers] = getFormikField({ id: id || '' });

  const handleLabelChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (helpers?.setValue) {
      // When InputField is wrapped in Formik
      helpers?.setValue({
        id,
        label: e.target.value,
        type,
        values: field?.value?.values,
        files: field?.value?.files
      });
    }
  };

  const handleDeleteField = () => {
    if (helpers?.setValue) {
      // When InputField is wrapped in Formik
      helpers?.setValue(undefined, false);
    }

    deleteField({ id });
  };

  const changeFieldType = ({ id, fieldType }: { id?: string; fieldType: TFieldType }) => {
    setCustomFields((prev) => {
      const currentFields: TCustomField[] = JSON.parse(JSON.stringify(prev));
      const selectedField = currentFields.find((field) => field.id === id);
      if (selectedField) {
        selectedField.type = fieldType;
      }

      return currentFields;
    });

    if (helpers?.setValue) {
      // When InputField is wrapped in Formik
      helpers?.setValue({
        id,
        label: '',
        type: fieldType,
        values: '',
        files: []
      });
    }

    setEditFieldModalData({ id: '', fieldType: 'label' });
    handleToggleOpenEditFieldsModal();
  };

  const handleToggleOpenEditFieldsModal = () => {
    toggleOpenModal({ modalId: editProgramDetailsDateUploadFileFieldsId });
  };

  const openChangeFieldModal = ({ id, fieldType }: { id?: string; fieldType: TFieldType }) => {
    setEditFieldModalData({ fieldType, id: id || '' });
    handleToggleOpenEditFieldsModal();
  };

  const onMediaLoad = (
    data: string | ArrayBuffer | null,
    fileType: string,
    mediaType: string,
    name: string,
    size: number
  ) => {
    if (helpers?.setValue) {
      // When InputField is wrapped in Formik
      let files: TProgramFileType[] = [];

      try {
        files = field?.value?.files || [];
      } catch { }

      const newId = getUuid();
      const newFile: TProgramFileType = { key: newId, fileName: name, fileSize: size, data };

      files.push(newFile);
      helpers?.setValue({
        id,
        label: field?.value?.label,
        type,
        files,
        values: 'file selected'
      });
    }
  };

  const files: TProgramFileType[] = field?.value?.files;
  // useEffect(() => {
  //   if (!field?.value) {
  //     helpers?.setValue({
  //       id,
  //       type,
  //       label: rest?.label || '',
  //       placeholder: '',
  //       values: '',
  //       files: rest?.files || []
  //     });
  //   }
  // }, [id]);
  const removeFile = (file: TProgramFileType, index: number) => {
    const filterFile = files.filter((singleFile: TProgramFileType) => singleFile?.key !== file.key);
    helpers?.setValue({
      id,
      type,
      label: field?.value?.label || '',
      placeholder: '',
      values: field?.value?.values,
      files: filterFile
    });
  }
  
  return (
    <Card key={id} className="dynamic-field">
      <div className="dynamic-field__header">
        <Typography as="span" variant="caption" fontWeight="semiBold">
          Upload file field
        </Typography>
        <div className="dynamic-field__header__actions">
          <ActionIcon
            onClick={() => {
              openChangeFieldModal({ id, fieldType: type });
            }}
          >
            <EditIcon />
          </ActionIcon>
          <ActionIcon onClick={handleDeleteField}>
            <TrashIcon />
          </ActionIcon>
        </div>
      </div>

      <InputField
        label="Field title"
        id={`${id}-label`}
        variant={componentSizeVariantsEnum.SMALL}
        placeholder="Enter field title"
        type="text"
        onChange={handleLabelChange}
        value={field?.value?.label}
        errorMessage={errors?.[`${id}-label`]}
        isTouched={true}
      />

      <div
        className="dynamic-field__upload-file"
        draggable
        ref={dragDropRef}
        onDrop={(e) => {
          e.preventDefault();
          handleUploadMedia({ e, onLoad: onMediaLoad, mediaType: 'program', dragDrop: true });
        }}
        onDragOver={(e) => {
          e.preventDefault();
        }}
      >
        <span className="dynamic-field__upload-file__drag-drop">
          <UploadIcon />
          <Typography as="p" variant="body-2" fontWeight="regular">
            Drag and drop your logo
          </Typography>
          <input
            type="file"
            hidden
            ref={uploadImageRef}
            accept=".bmp,.jpg,.jpeg,.png,.pdf,.doc,.docx,.mp4"
            className="select-post-image"
            onChange={(e) => {
              handleUploadMedia({ e, onLoad: onMediaLoad, mediaType: 'program' });
            }}
          />
        </span>
        <Typography as="p" variant="body-2" fontWeight="regular">
          or
        </Typography>
        <Typography
          as="span"
          variant="body-2"
          fontWeight="medium"
          className="dynamic-field__upload-file__browse"
          onClick={openImageFileManager}
        >
          Browse on your device
        </Typography>
      </div>

      {<ErrorMessage message={errors?.[id]} />}

      {files?.length > 0 && (
        <div className="dynamic-field__files">
          {files.map(({ key, ...file }, index: number) => {
            const fileName = file?.fileName;

            // eslint-disable-next-line no-undef
            const mimeType = getMimeType({
              fileName
            });

            return (
              <FileCard
                removeFile={() => removeFile({ ...file, key }, index)} key={key}
                {...file}
                onClick={(e: any) => {
                  e.preventDefault()
                  e.stopPropagation()
                  try {
                    // eslint-disable-next-line no-undef
                    const blob = new Blob([file?.data as unknown as BlobPart], { type: mimeType });
                    // Create a URL for the Blob
                    const url = URL.createObjectURL(blob);
                    window.open(url);
                  } catch (e) {
                    console.log(e);
                  }
                }} />
            );
          })}
        </div>
      )}

      {isEditProgramDetailsFieldsOpen && (
        <AddEditProgramDetailsFields
          isModalOpen={isEditProgramDetailsFieldsOpen}
          onModalClose={handleToggleOpenEditFieldsModal}
          title="Edit Fields"
          currentType={editFieldModalData.fieldType}
          id={editFieldModalData.id}
          onSelection={changeFieldType}
        />
      )}
    </Card>
  );
};

export default UploadField;
