import { useMemo } from 'react';
import {
  Button,
  Typography
} from 'src/components/common';

import cx from './viewAction.module.scss';
import { TActionButton } from './viewCohort.types';
import { MdAdd } from 'react-icons/md';

const CohortAction = () => {
  return (
    <div className={cx.cohortActions}>
      <div className={cx.label}>
        <Typography variant="subHeading-2" fontWeight="semiBold">
          Cohort Details
        </Typography>
      </div>
      <div className={cx.actions}>
        <div className={cx.header}>
          <h5 className={cx.cohortName}>Demo Cohort</h5>
          <div className={cx.dates}>
            <p>Start Date : 25 October, 2024</p>
            <p>End Date : 20 November, 2024</p>
            <p>(26 remaining days)</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CohortAction;
