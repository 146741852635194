import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BackNavigation, Typography, ConfirmModal } from 'src/components/common';
import Header from 'src/components/header/Header';
import CompanyInfoCard from 'src/components/company/companyinfo/CompanyInfoCard';
import {
  ApplicantDetails,
  ApplicationFormStep,
  ApplyProgramProgramDetails,
  ProgramClosed,
  TApplicationDetailsFormValues,
  TApplicationFormStepSectionItem
} from 'src/components/company/programs/applyToProgram';
import { colors } from 'src/constants';
import Button from 'src/components/button/Button';
import {
  TCreateApplicationRequest,
  TGetProgramData,
  TGetProgramRequest,
  TUploadApplicationFileAction,
  TAppliedProgramsListRequest
} from 'src/services/apiEndpoint.types.ts';
import { useGetIdentity, useUrlParamValue } from 'src/hooks';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import {
  createApplication,
  getProgram,
  getUploadApplicationFile,
  resetViewProgram,
  appliedProgramsList
} from 'src/store/program/program.slice';
import { StorageUtils } from 'src/utils'
import { TSelectedFiles } from 'src/components/company/programs/applyToProgram/applicationFormStep/applicationFormStep.types';
import { getViewCompany } from 'src/store/company/company.slice';
import './applyToProgram.scss';
import BootstrapModal from 'src/components/common/BootstrapModal/BootstrapModal';
import { httpRequest } from 'src/services/commonApis';
import store from 'src/store/store';

const ApplyToProgram = () => {
  // Hooks
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { paramValue } = useUrlParamValue();
  const { getIdentities } = useGetIdentity();
  const { viewCompany, viewCompanyProfilePic } =
    useAppSelector((store) => store.company);
  const { isAuthorized } = useAppSelector((store) => store.auth);
  const viewProgram: TGetProgramData = useAppSelector((store) => store.program.viewProgram);

  // React hooks
  const [applyProgramStep, setApplyProgramStep] = useState<number>(0);
  const [applicantDetails, setApplicantDetails] = useState<TApplicationDetailsFormValues>({
    name: '',
    email: '',
    mobile: ''
  });
  const { appliedProgramsPageNumber, appliedProgramsPerPage, appliedPrograms } = useAppSelector(
    (store) => store.program
  );
  const [applicantSectionResponses, setApplicantSectionResponses] = useState<
    { sectionTitle: string; sectionItems: TApplicationFormStepSectionItem[] }[]
  >(viewProgram?.applicationForm?.sections);

  const ApplicationDraft: any = StorageUtils.get('ApplicationDraft');
  const [applicationFiles, setApplicationFiles] = useState<TSelectedFiles>({});
  const [show, setShow] = useState<boolean>(false);
  const programId: string = paramValue({ paramName: 'id' }) as string;
  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
  const [alldreadyApplied, setAlldreadyApplied] = useState<boolean>(false);
  // const { loggedInUserId, authenticatedId, loggedInUserType } = getIdentities();
  const { loggedInUserId, authenticatedId } = getIdentities();
  const [resourceData, setResourceData] = useState<any>([])

  const handleClose = () => setShow(false)
  const handleGoBack = () => {
    if (applyProgramStep > 0) {
      setApplyProgramStep((prev) => prev - 1);
    } else {
      navigate('/', { replace: true });
    }
  };
  const goToApplicationHome = () => {
    StorageUtils.remove('ApplicationDraft')
    navigate('/my-profile');
  };

  const handleNext = () => {
    setApplyProgramStep((prev) => {
      console.log(steps?.length, 'emailll')
      if (prev + 1 < steps?.length) {
        return prev + 1;
      }
      return prev;
    });
  };

  const updateApplicantDetails = ({ name, email, mobile }: TApplicationDetailsFormValues) => {
   if (alldreadyApplied) {
    setOpenCancelModal(true)
   } else {
    setApplicantDetails({ name, email, mobile });

    handleNext();
   }
  };

  const steps = [<ApplyProgramProgramDetails key="applyProgramProgramDetails" type='application'/>];

  const updateApplicantSectionResponse = ({
    sectionIndex,
    sectionTitle,
    sectionResponses,
    selectedFiles
  }: {
    sectionIndex: number;
    sectionTitle: string;
    sectionResponses: TApplicationFormStepSectionItem[];
    selectedFiles: TSelectedFiles;
  }) => {
    setApplicantSectionResponses((prev) => {
      const currentSections = JSON.parse(JSON.stringify(prev));

      currentSections[sectionIndex] = { sectionTitle, sectionItems: sectionResponses };

      return currentSections;
    });

    setApplicationFiles((prev) => {
      const currentItems: TSelectedFiles = JSON.parse(JSON.stringify(prev));
      const output = { ...currentItems, ...selectedFiles };

      return output;
    });

    handleNext();
  };

  const handleApplyToProgram = ({
    sectionIndex,
    sectionTitle,
    sectionResponses,
    selectedFiles
  }: {
    sectionIndex: number;
    sectionTitle: string;
    sectionResponses: TApplicationFormStepSectionItem[];
    selectedFiles: TSelectedFiles;
  }) => {
    const currentSections = JSON.parse(JSON.stringify(applicantSectionResponses));

    currentSections[sectionIndex] = { sectionTitle, sectionItems: sectionResponses };
    const combinedFiles = { ...applicationFiles, ...selectedFiles };

    const payload: TCreateApplicationRequest = {
      programId: programId,
      userId: loggedInUserId,
      draftId: ApplicationDraft?.id,
      name: applicantDetails?.name,
      email: applicantDetails?.email,
      mobile: applicantDetails?.mobile,
      response: { sections: currentSections }
    };

    dispatch(createApplication(payload)).then((response) => {
      if (ApplicationDraft?.id) {
        StorageUtils.remove('ApplicationDraft')
      }
      const applicationId = response?.data?.application?.id || '';
      console.log(combinedFiles, 'combinedFiles')
      Object.keys(combinedFiles).forEach((key) => {
        try {
          const files = combinedFiles[key];
          files.forEach((file) => {
            const fileBytes: any = file.data
            console.log(fileBytes.byteLength > 0, 'fileBy')
            if (fileBytes.byteLength > 0) {
              const payload: TUploadApplicationFileAction = {
                programId: programId,
                applicationId,
                id: file?.key,
                fileName: file?.fileName,
                data: file?.data || ''
              };

              dispatch(getUploadApplicationFile(payload));
            }
          });
        } catch (e) {
          console.log(e);
        }
      });
    });
  };

  const handleDraft = ({
    sectionIndex,
    sectionTitle,
    sectionResponses,
    selectedFiles
  }: {
    sectionIndex: number;
    sectionTitle: string;
    sectionResponses: TApplicationFormStepSectionItem[];
    selectedFiles: TSelectedFiles;
  }) => {
    const currentSections = JSON.parse(JSON.stringify(applicantSectionResponses));
    currentSections[sectionIndex] = { sectionTitle, sectionItems: sectionResponses };
    const combinedFiles = { ...applicationFiles, ...selectedFiles };
    console.log(combinedFiles, 'combinedFiles')
    const payload: TCreateApplicationRequest = {
      programId: programId,
      userId: loggedInUserId,
      name: applicantDetails?.name,
      email: applicantDetails?.email,
      mobile: applicantDetails?.mobile,
      draftId: ApplicationDraft?.id,
      Program: viewProgram,
      draft: true,
      response: { sections: currentSections }
    };
    dispatch(createApplication(payload)).then((response) => {
      if (ApplicationDraft?.id) {
        StorageUtils.remove('ApplicationDraft')
      }
      console.log(response?.data, 'response?.data?')
      const applicationId = response?.data?.application?.id || '';

      Object.keys(combinedFiles).forEach((key) => {
        try {
          const files = combinedFiles[key];

          files.forEach((file) => {
            const payload: TUploadApplicationFileAction = {
              programId: programId,
              applicationId,
              id: file?.key,
              fileName: file?.fileName,
              data: file?.data || ''
            };

            dispatch(getUploadApplicationFile(payload));
          });
        } catch (e) {
          console.log(e);
        }
      });
    });
  };

  try {
    applicantSectionResponses?.forEach((section, index) => {
      const { sectionTitle, sectionItems } = section;

      const totalSteps: number = applicantSectionResponses?.length;

      const finalStep: boolean = totalSteps === index + 1;

      steps.push(
        <ApplicationFormStep
          sectionIndex={index}
          key={`${sectionTitle}-${index}`}
          sectionTitle={sectionTitle}
          sectionItems={sectionItems}
          handleNext={handleNext}
          handleDraft={handleDraft}
          finalStep={finalStep}
          updateApplicantSectionResponse={updateApplicantSectionResponse}
          handleApplyToProgram={handleApplyToProgram}
          goToApplicationHome={goToApplicationHome}
        />
      );
    });
  } catch { }

  async function getResources() {
    const response = await httpRequest(`resource?programId=${programId}`, 'GET', null, 'json', 'program');
    if (response.body?.length > 0) {
      setResourceData(response.body)
    }
  }
  const handleViewCompany = () => {
    navigate(`/view-company?id=${viewProgram?.companyId}`);
  };

  const handleGetProgram = () => {
    const payload: TGetProgramRequest = {
      programId: programId,
      userId: authenticatedId
      // userType: loggedInUserType
    };

    dispatch(getProgram(payload));
  };

  const handleGetCompany = () => {
    dispatch(getViewCompany({ id: viewProgram?.companyId }));
  };

  useEffect(() => {
    if (programId && programId !== '0' && programId !== '') {
      handleGetProgram();
    }
    return () => {
      dispatch(resetViewProgram());
    };
  }, [window.location.search, programId]);

  useEffect(() => {
    if (viewProgram?.companyId && viewProgram?.companyId !== '0' && viewProgram?.companyId !== '') {
      handleGetCompany();
    }
  }, [viewProgram?.companyId]);

  useEffect(() => {
    if (!StorageUtils.get('ApplicationDraft')) {
      console.log('trrrrrrrr')
      setApplicantSectionResponses(viewProgram?.applicationForm?.sections);
    }
  }, [JSON.stringify(viewProgram?.applicationForm?.sections)]);

  const navigateToInternalApplication = () => {
    if (!isAuthorized) {
      navigate(`/apply-to-program-external?id=${programId}`);
    }
  };
  useEffect(() => {
    navigateToInternalApplication();
  }, [isAuthorized]);

  useEffect(() => {
    getResources()
  }, [programId])
  useEffect(() => {
    if (ApplicationDraft) {
      console.log(ApplicationDraft?.response?.sections, 'ApplicationDraft?.response?.sections')
      setApplicantSectionResponses(ApplicationDraft?.response?.sections);
    }
  }, [])
  useEffect(() => {
    console.log(applicantSectionResponses, 'currentSections')
  }, [applicantSectionResponses])
  console.log(viewProgram, 'viewProgram', viewCompany);
  useEffect(() => {
    const payload: TAppliedProgramsListRequest = {
      userId: loggedInUserId,
      limit: appliedProgramsPerPage,
      offset: appliedProgramsPageNumber
    };
    dispatch(appliedProgramsList(payload));
   }, [])
   useEffect(() => {
   const data:any = appliedPrograms.find(e => e.programId === programId)
   console.log(data, 'jjjjjjjjjjjj')
   if (data) {
   if (data.draft) {
    const applicantDraftedData:TApplicationDetailsFormValues = {
      name: data?.name,
      email: data?.name,
      mobile: data?.mobile
         }
   setApplicantDetails(applicantDraftedData)
   setApplicantSectionResponses(data?.response?.sections);
   StorageUtils.set('ApplicationDraft', data)
   } else {
    // setOpenCancelModal(true)
    setAlldreadyApplied(true)
   }
   }
   }, [appliedPrograms])

   console.log(applyProgramStep, 'applyProgramStep');
  return (
    <div className="apply-to-program">
      <Header />
      <div className="apply-to-program__body">
      {applyProgramStep !== 1 && <BackNavigation text={applyProgramStep === 0 ? "Back to home" : 'Back'} onClick={handleGoBack} />}

        <div className="view-company__body__content__body">
          <CompanyInfoCard
            name={viewCompany.name}
            type="{type}"
            introduction={viewCompany.introduction}
            website="{website}"
            contactUsUrl="{contactUsUrl}"
            profilePic={viewCompanyProfilePic}
            dropdownItems={[]}
            programBanner={viewProgram?.banner}
          />
        </div>
        <div className="apply-to-program__body__step">
          <div className="apply-to-program__body__step__component">
            <div className="apply-to-program__body__step__component__header">
              <Typography
                variant="subHeading-2"
                fontWeight="semiBold"
                style={{ color: colors.neutral[1100] }}
              >
                {applyProgramStep === 0 ? 'Apply Program' : 'Application form'}
              </Typography>
              <div className='d-flex gap-2'>
                <Button size="extraSmall" variant="secondary" onClick={handleViewCompany}>
                  View company profile
                </Button>
                {resourceData?.length > 0 && <Button size="extraSmall" variant="secondary" onClick={() => setShow(true)}>
                  View resources
                </Button>}
              </div>
            </div>
            {show && <BootstrapModal show={show} handleClose={handleClose} title={'resources'} type='resources' resourceData={resourceData} />}
            {steps[applyProgramStep]}
          </div>
          {applyProgramStep === 0 && viewProgram?.status !== 'Closed' && (
            <ApplicantDetails updateApplicantDetails={updateApplicantDetails} applicantDetails={applicantDetails} />
          )}{' '}
          {applyProgramStep === 0 && viewProgram?.status === 'Closed' && (
            <ProgramClosed title={viewProgram?.title} endDate={viewProgram?.endDate} />
          )}
        </div>
      </div>
      {openCancelModal && (
        <ConfirmModal
          openConfirmModal={openCancelModal}
          title="You have already applied to this program"
          description="you can view the application in the My profile >> Applied programs section"
          onClose={() => {
            setOpenCancelModal(false);
          }}
          onSubmit={goToApplicationHome}
          submitText="Go to Profile"
          type="error"
        />
      )}
    </div>
  );
};

export default ApplyToProgram;
