import { componentSizeVariantsEnum } from 'src/constants';
import Label from 'src/components/common/formFields/label/Label';
import { TSelectCardRadioButton } from 'src/components/common/formFields/selectCardRadio/SelectCardRadio';
import SelectField from 'src/components/common/formFields/selectField/SelectField';
import { useGetFormikField } from 'src/hooks';
import ErrorMessage from 'src/components/common/formFields/errorMessage/ErrorMessage';
import { selectDeselectCheckbox } from 'src/utils';

import './previewField.scss';
import { TPreviewOptionsFieldProps } from './previewField.types';
import React from 'react';
const OptionsField = ({ id, label, options, type, required, onChange, value }: TPreviewOptionsFieldProps) => {
  const fieldOptions: TSelectCardRadioButton[] = options.map((option) => ({
    id: option,
    label: option,
    value: option
  }));
  console.log(fieldOptions, 'fieldoption')

  // Hooks
  const { getFormikField } = useGetFormikField();

  const [field, fieldMetaData, helpers] = getFormikField({ id: id || '' });

  const showErrorMessage = fieldMetaData?.touched && fieldMetaData?.error;

  return (
    <div key={id} className="dynamic-field">
      <div className="dynamic-field__field">
        {' '}
        <Label required={required}>{label}</Label>
        {options && options?.length > 0 && type === 'multipleChoice' && (
          <div className="dynamic-field__field__dropdown-options dynamic-field__field__radio-check">
            {options.map((option, index) => {
              console.log(field?.value, 'fieldOption', option);

              return (
                <div className="dynamic-field__field__dropdown-options__checkbox" key={index}>
                  <input
                    type="checkbox"
                    name={label}
                    id={option}
                    value={option}
                    checked={field?.value?.split(',')?.indexOf(option) > -1}
                    onChange={(e) => {
                      const newArray: string[] = !field?.value ? [] : field?.value?.split(',');
                      const updatedArray = selectDeselectCheckbox({
                        arr: [...newArray],
                        value: e.target.value
                      });
                      helpers?.setValue(updatedArray.join());
                    }}
                  />
                  <Label htmlFor={option}>{option}</Label>
                </div>
              );
            })}
          </div>
        )}
        {options && options?.length > 0 && type === 'singleChoice' && (
          <div className="dynamic-field__field__dropdown-options dynamic-field__field__radio-check">
            {options.map((option, index) => {
              return (
                <div className="dynamic-field__field__dropdown-options__radio" key={index}>
                  <input
                    type="radio"
                    name={label}
                    id={option}
                    checked={field?.value ? option === field?.value : value ? value === option : false}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      // eslint-disable-next-line no-unused-expressions
                      helpers?.setValue ? helpers?.setValue(option) : onChange ? onChange(e) : null
                    }}
                  />
                  <Label htmlFor={option}>{option}</Label>
                </div>
              );
            })}
          </div>
        )}
        {options && options?.length > 0 && type === 'dropdown' && (
          <div className="dynamic-field__field__dropdown-options">
            <SelectField
              placeholder="Select from below"
              variant={componentSizeVariantsEnum.SMALL}
              selectedValue={field?.value}
              options={fieldOptions}
              onSelection={({ value }) => {
                helpers?.setValue(value);
              }}
            />
          </div>
        )}
      </div>
      {showErrorMessage && <ErrorMessage message={fieldMetaData?.error} />}
    </div>
  );
};

export default OptionsField;
