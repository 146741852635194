import React from 'react';
import cx from './teams.module.scss';
import { Col, Row } from 'react-bootstrap';
import { ProfilePicture, Typography } from 'src/components/common';

export default function Teams({ handleClick }: any) {
  return (
    <div className={cx.teams}>
      <Row>
        <Col md={6}>
          <h5 className={`${cx.chatHeading}`}>Teams / Startups</h5>
          <div className='insideCard transparent'>
            <div className={cx.chatBox}>
              <Row>
                <Col md={6}>
                  <div className={`${cx.teamCard}`} onClick={() => handleClick('Relationship')}>
                    <ProfilePicture
                      key={"id1"}
                      url={''}
                      fallback={'A'}
                    />
                    <div>
                      <Typography
                        as="p"
                        variant="body-2"
                        fontWeight="semiBold"

                      >
                        {'Adil'}
                      </Typography>
                      <Typography
                        as="p"
                        variant="caption"
                        fontWeight="regular"

                      >
                        {'Enterpreneur'}
                      </Typography>
                    </div>

                  </div>
                </Col>
                <Col md={6}>
                  <div className={`${cx.teamCard}`} onClick={() => handleClick('Relationship')}>
                    <ProfilePicture
                      key={"id2"}
                      url={''}
                      fallback={'A'}
                      className="key-member-card__header__user-info__profile-pic"
                    />
                    <div className="key-member-card__header__user-info__user">
                      <Typography
                        as="p"
                        variant="body-2"
                        fontWeight="semiBold"
                        className="key-member-card__header__user-info__user__name"
                      >
                        {'Adil'}
                      </Typography>
                      <Typography
                        as="p"
                        variant="caption"
                        fontWeight="regular"
                        className="key-member-card__header__user-info__user__role"
                      >
                        {'Enterpreneur'}
                      </Typography>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className={`${cx.teamCard}`} onClick={() => handleClick('Relationship')}>
                    <ProfilePicture
                      key={"id3"}
                      url={''}
                      fallback={'A'}
                      className="key-member-card__header__user-info__profile-pic"
                    />
                    <div className="key-member-card__header__user-info__user">
                      <Typography
                        as="p"
                        variant="body-2"
                        fontWeight="semiBold"
                        className="key-member-card__header__user-info__user__name"
                      >
                        {'Adil'}
                      </Typography>
                      <Typography
                        as="p"
                        variant="caption"
                        fontWeight="regular"
                        className="key-member-card__header__user-info__user__role"
                      >
                        {'Enterpreneur'}
                      </Typography>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className={`${cx.teamCard}`} onClick={() => handleClick('Relationship')}>
                    <ProfilePicture
                      key={"id4"}
                      url={''}
                      fallback={'A'}
                      className="key-member-card__header__user-info__profile-pic"
                    />
                    <div className="key-member-card__header__user-info__user">
                      <Typography
                        as="p"
                        variant="body-2"
                        fontWeight="semiBold"
                        className="key-member-card__header__user-info__user__name"
                      >
                        {'Adil'}
                      </Typography>
                      <Typography
                        as="p"
                        variant="caption"
                        fontWeight="regular"
                        className="key-member-card__header__user-info__user__role"
                      >
                        {'Enterpreneur'}
                      </Typography>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col md={6}>
          <Row>
            <Col md={7}>
              <h5 className={`${cx.chatHeading}`}>Group Chat</h5>
              <div className='insideCard transparent'>
                <div className={cx.chatBox}>

                </div>
              </div>
            </Col>
            <Col md={5}>
              <h5 className={`${cx.chatHeading}`}>Upcoming Events</h5>
              <div className='insideCard'>
                <ul className={`${cx.activityList}`}>
                  <li>Test Events</li>
                  <li>Test Events</li>
                  <li>Test Events</li>
                </ul>
              </div>
              <h5 className={`${cx.chatHeading}`}>Deadline Approching</h5>
              <div className='insideCard'>
                <ul className={`${cx.activityList}`}>
                  <li>Test</li>
                  <li>Test</li>
                  <li>Test</li>
                </ul>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}
