/* eslint-disable no-unneeded-ternary */
import { createContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCopyToClipboard, useGoBack, useToggleOpenModal, useUrlParamValue } from 'src/hooks';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { assignModalId } from 'src/store/common/common.slice';
import Header from 'src/components/header/Header';
import {
  BackNavigation,
  Button,
  InfiniteScroll,
  TDropdownOptions,
  TTabItem,
  Tabs,
  Typography
} from 'src/components/common';
import {
  About as AboutCompany,
  Activities,
  CompanyProfileCard,
  InviteMentorInvestor,
  inviteMentorInvestorModalId,
  KeyMembers,
  Programs
  // Services
} from 'src/components/company';
import { StorageUtils } from 'src/utils';
// import { getCompany, getServices, incrementKeyMembersPage } from 'src/store/company/company.slice';
import { getCompany, incrementKeyMembersPage } from 'src/store/company/company.slice';
import { TGetCompany, TGetUserStatsRequest } from 'src/services/apiEndpoint.types.ts';
import { TUserTypes } from 'src/constants/user.constants';
import { getUserStats, incrementNewsFeedPage } from 'src/store/socialMedia/socialMedia.slice';
import { incrementProgramsPage } from 'src/store/program/program.slice';
import { InviteNetwork, StartupNewsResources } from 'src/components/cards';
import { PlusIcon } from 'src/components/common/common.icons';
import ResoucesData from 'src/components/company/Resources/Resouces';
import './myCompany.scss';
import Cohorts from 'src/components/company/programs/Cohorts/Cohorts';
export type TMyCompanyTabs = 'about' | 'services' | 'programs' | 'key-members' | 'activities';
export const SelfCompanyContext = createContext(false);
export const editCompanyModalId = 'edit-company-modal';

const MyCompany = () => {
  // Hooks
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { paramValue } = useUrlParamValue();
  const { copyToClipboard } = useCopyToClipboard();
  const goBack = useGoBack();
  const toggleOpenModal = useToggleOpenModal();
  const { modalId } = useAppSelector((store) => store.common);

  const handleToggleInviteMentorInvestorModal = () => {
    toggleOpenModal({ modalId: inviteMentorInvestorModalId });
  };

  // Constants
  const isInviteMentorInvestorModalOpen = modalId === inviteMentorInvestorModalId;

  const { loading } = useAppSelector<{ modalId: string; loading: boolean }>(
    (store) => store.common
  );
  const { newsFeedMoreItemsLeft } = useAppSelector((store) => store.socialMedia);
  const {
    myCompany,
    companyProfilePic,
    keyMembersLoading,
    keyMembersMoreItemsLeft
  }: {
    myCompany: TGetCompany;
    keyMembersPageNumber: number;
    keyMembersItemsPerPage: number;
    companyProfilePic: string;
    keyMembersLoading: boolean;
    keyMembersMoreItemsLeft: boolean;
  } = useAppSelector((store) => store.company);
  const { moreProgramsLeft } = useAppSelector((store) => store.program);

  const companyId = StorageUtils.get('companyId') as string;
  const userType = StorageUtils.get('userType') as TUserTypes;

  const prevRoute = paramValue({ paramName: 'prev' });
  const currentTab = (paramValue({ paramName: 'tab' }) || 'about') as TMyCompanyTabs;

  const hasPrevRoute = prevRoute && prevRoute !== 'null';

  // const fetchServices = () => {
  //   dispatch(getServices({ id: companyId }));
  // };

  const loadMoreItems = () => {
    switch (currentTab) {
      case 'activities': {
        if (newsFeedMoreItemsLeft && !loading) {
          dispatch(incrementNewsFeedPage(1));
        }
        break;
      }
      case 'key-members': {
        if (!keyMembersLoading && keyMembersMoreItemsLeft) {
          dispatch(incrementKeyMembersPage(1));
        }
        break;
      }
      case 'programs': {
        if (!loading && moreProgramsLeft) {
          dispatch(incrementProgramsPage(1));
        }
        break;
      }
      default: {
        break;
      }
    }
  };

  const InfiniteScrollLoading: boolean =
    currentTab === 'activities' ? loading : currentTab === 'programs' ? loading : keyMembersLoading;
  const moreItemsLeft: boolean =
    currentTab === 'activities'
      ? newsFeedMoreItemsLeft
      : currentTab === 'key-members'
        ? keyMembersMoreItemsLeft
        : currentTab === 'programs'
          ? moreProgramsLeft
          : false;

  // Footer is not required when there is no infinite scrolling
  const showFooter =
    currentTab === 'activities' || currentTab === 'key-members' || currentTab === 'programs'
      ? true
      : false;

  const {
    name,
    type,
    about,
    website,
    introduction,
    foundedYear,
    industry,
    contactNumber,
    companyEmailAddress,
    contactUsUrl,
    address,
    linkedin,
    instagram,
    facebook,
    twitter,
    addressLine1,
    addressLine2,
    city,
    state,
    country,
    zipCode
  } = myCompany;
  const tabItems: TTabItem[] = useMemo(() => {
    const tabs = [
      {
        key: 'about',
        text: 'About',
        disabled: false,
        component: (
          <AboutCompany
            about={about}
            foundedYear={foundedYear}
            industry={industry}
            address={address}
            email={companyEmailAddress}
            linkedin={linkedin}
            instagram={instagram}
            twitter={twitter}
            facebook={facebook}
            contactNumber={contactNumber}
            addressLine1={addressLine1}
            addressLine2={addressLine2}
            city={city}
            state={state}
            country={country}
            zipCode={zipCode}
            type={type}
          />
        )
      },
      {
        key: 'activities',
        text: 'Activities',
        disabled: false,
        component: (
          <Activities viewCompanyId={companyId} viewerId={companyId} viewerType="Company" />
        )
      }
    ];

    // if (myCompany.type !== 'Incubator') {
    //   tabs.push({
    //     key: 'services',
    //     text: 'Services',
    //     disabled: false,
    //     component: <Services fetchServices={fetchServices} />
    //   })
    // }

    if (myCompany.type === 'Incubator' || myCompany.type === 'Accelerator') {
      tabs.splice(
        1,
        0,
        {
          key: 'programs',
          text: 'Programs',
          disabled: false,
          component: <Programs companyId={companyId} />
        },
        // {
        //   key: 'cohorts',
        //   text: 'Cohorts',
        //   disabled: false,
        //   component: <Cohorts companyId={companyId} />
        // },
        {
          key: 'key-members',
          text: 'Key Members',
          disabled: false,
          component: <KeyMembers companyIdToFetch={companyId} />
        },
        {
          key: 'resources',
          text: 'Resources',
          disabled: false,
          component: (
            <ResoucesData viewCompanyId={companyId} viewerId={companyId} viewerType="Company" />
          )
        }
      );
    }

    return tabs;
  }, [userType, JSON.stringify(myCompany)]);

  const copyProfileLink = () => {
    copyToClipboard({ text: `${window.location.host}/view-company?id=${companyId}` });
  };

  const handleEditCompanyProfile = () => {
    dispatch(assignModalId(editCompanyModalId));
  };

  // const handleDeleteCompanyPage = () => {};

  const changeMyCompanyTab = (value: string) => {
    navigate(`/my-company?tab=${value as TMyCompanyTabs}&prev=${prevRoute}`);
  };

  const companyCardDropdownItems: TDropdownOptions[] = [
    { label: 'Copy profile link', onClick: copyProfileLink },
    { label: 'Edit profile', onClick: handleEditCompanyProfile }
    // { label: 'Delete page', onClick: handleDeleteCompanyPage }
  ];

  const handleGetUserStats = ({ id }: { id: string }) => {
    const payload: TGetUserStatsRequest = {
      userId: id,
      userType: 'Company',
      requestorId: companyId,
      requestorType: 'Company'
    };

    dispatch(getUserStats(payload));
  };

  useEffect(() => {
    dispatch(getCompany({ id: companyId }));

    handleGetUserStats({ id: companyId });
  }, []);

  return (
    <SelfCompanyContext.Provider value={true}>
      <InfiniteScroll
        className="my-company"
        onScrollEnd={loadMoreItems}
        loading={InfiniteScrollLoading}
        showLoader={currentTab === 'key-members' ? false : true}
        moreItemsLeft={moreItemsLeft}
        showFooter={showFooter}
      >
        <Header />
        <div className="my-company__body">
          {hasPrevRoute && <BackNavigation text="Back" onClick={goBack} />}
          <div className="my-company__body__title">
            <Typography as="p" variant="subHeading-1" fontWeight="semiBold">
              My Company Profile
            </Typography>
            {(myCompany.type === 'Incubator' || myCompany.type === 'Accelerator') && (
              <Button
                startIcon={<PlusIcon />}
                variant="secondary"
                size="small"
                onClick={handleToggleInviteMentorInvestorModal}
              >
                Invite Mentor/Investor
              </Button>
            )}
          </div>
          <div className="my-company__body__content">
            <div className="my-company__body__content__body">
              <CompanyProfileCard
                name={name}
                type={type}
                introduction={introduction}
                website={website}
                contactUsUrl={contactUsUrl}
                profilePic={companyProfilePic}
                dropdownItems={companyCardDropdownItems}
              />
              <Tabs activeItem={currentTab} onTabChange={changeMyCompanyTab} items={tabItems} />
            </div>
            <div className="my-company__body__content__cards">
              <InviteNetwork />
              <StartupNewsResources />
            </div>
          </div>
        </div>
      </InfiniteScroll>
      {isInviteMentorInvestorModalOpen && (
        <InviteMentorInvestor
          isModalOpen={isInviteMentorInvestorModalOpen}
          onModalClose={handleToggleInviteMentorInvestorModal}
        />
      )}
    </SelfCompanyContext.Provider>
  );
};

export default MyCompany;
