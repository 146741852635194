import React from 'react';
import cx from './cohortsidebar.module.scss';

export default function CohortSidebar({ handleClick, activeTab }: any) {
    return (
        <div className={`${cx.sidebar}`}>
            <div className="insideCard">
                <ul className={cx.sidebaritemList}>
                    <li className={activeTab === 'Dashboard' ? cx.active : ''} onClick={() => handleClick('Dashboard')}>Dashboard</li>
                    <li className={(activeTab === 'Teams' || activeTab === 'Relationship') ? cx.active : ''} onClick={() => handleClick('Teams')}>Teams</li>
                    <li className={activeTab === 'Investment' ? cx.active : ''} onClick={() => handleClick('Investment')}>Investment Tracking</li>
                    <li className={activeTab === 'Resource' ? cx.active : ''} onClick={() => handleClick('Resource')}>Resource</li>
                </ul>
            </div>
        </div>
    )
}
